<!--
Edit form for PvDimensioningSchema (used for measures and scenarios)
-->
<i18n>
{
  "de": {
    "coverageTargetTitle": "Deckungsziel",
    "roofMinYieldTitle": "Mindestertrag",
    "roofMinModulesTitle": "Mindestgrösse",
    "roofMaxShapeFactorTitle": "Max. Formfaktor",
    "fractionTitle": "Anteil nutzbar",
    "roofFractionInfoTitle": "Dachanteil nutzbar für Photovoltaik",
    "roofFractionInfoText": "Anteil der Dachfläche der für Photovoltaik genutzt werden kann. Wenn dieses Feld leer gelassen wird, wird 100% angenommen.",
    "fassadeMinModulesTitle": "Mindestgrösse",
    "fassadeMinYieldTitle": "Mindestertrag",
    "roofTitle": "Dachflächen",
    "fassadeTitle": "Fassadenflächen",
    "useFassadeTitle": "Fassade gebrauchen",
    "useFassadeInfoTitle": "Nutzung der Fassadenflächen",
    "useFassadeInfoText": "Fassadenflächen werden nur berücksichtigt, falls dies hier ausgewählt wird.",
    "activeLabel": "Ja",
    "inactiveLabel": "Nein",
    "coverageInfoTitle": "Ziel-Deckungsgrad Jahresstrombedarf",
    "coverageInfoText": "Dach- und Fassadenflächen, die die Kriterien erfüllen, werden geordnet nach dem grössten Stromertrag mit Photovoltaik-Modulen belegt, bis der Ziel-Deckungsgrad erreicht ist. Wenn dieses Feld leer gelassen wird, werden alle Flächen belegt.",
    "roofMinYieldInfoTitle": "Minimalertrag Dachflächen",
    "roofMinYieldInfoText": "Es werden nur Flächen mit grösserem Stromertrag berücksichtigt. Kann leer gelassen werden, wenn keine Untergrenze festgelegt werden soll.",
    "roofMinModulesInfoTitle": "Minimale Anzahl Module pro Dachfläche",
    "roofMinModulesInfoText": "Bezieht sich auf Standardmodule mit 100 x 160cm Grösse. Dachflächen, die diesen Grenzwert unterschreiten werden nicht berücksichtigt. Kann leer gelassen werden, wenn keine Untergrenze festgelegt werden soll.",
    "roofMaxShapeFactorInfoTitle": "Maximaler Formfaktor",
    "roofMaxShapeFactorInfoText": "Kennwert für Form der Dachfläche. 1.0 entspricht einem perfekten Quadrat. Unförmige oder sehr schmale Dachflächen haben grosse Formfaktoren. Empfohlener Maximalwert: 5.0",
    "fassadeMinYieldInfoTitle": "Minimalertrag Fassadenflächen",
    "fassadeMinYieldInfoText": "Es werden nur Flächen mit grösserem Stromertrag berücksichtigt. Kann leer gelassen werden, wenn keine Untergrenze festgelegt werden soll.",
    "fassadeMinModulesInfoTitle": "Minimale Anzahl Module pro Fassadenfläche",
    "fassadeMinModulesInfoText": "Nach Abzug des nutzbaren Anteils. Bezieht sich auf Standardmodule mit 100 x 160cm Grösse. Dachflächen, die diesen Grenzwert unterschreiten werden nicht berücksichtigt. Kann leer gelassen werden, wenn keine Untergrenze festgelegt werden soll.",
    "fassadeFractionInfoTitle": "Fassadenanteil nutzbar für Photovoltaik",
    "fassadeFractionInfoText": "Anteil der Fassadenfläche (inklusive Fenster, Balkone, etc.), der für Photovoltaik genutzt werden kann. Wenn dieses Feld leer gelassen wird, wird 100% angenommen."
  }
}
</i18n>

<template>
  <div class="edit-pv-dimensioning">
    <FormRow
      :label="$t('coverageTargetTitle')"
      :info-title="$t('coverageInfoTitle')"
      :info-text="$t('coverageInfoText')"
    >
      <NumericInput v-model="model.coverage_target" optional :edit="isEditing" :units="'%'" @input="onInput" @validation="onValidation($event, 'coverage-target')" />
    </FormRow>
    <h3>{{ $t('roofTitle') }}</h3>
    <FormRow
      :label="$t('roofMinYieldTitle')"
      :info-title="$t('roofMinYieldInfoTitle')"
      :info-text="$t('roofMinYieldInfoText')"
    >
      <NumericInput v-model="model.roof_min_yield" optional :edit="isEditing" :units="'kWh/kWp'" @input="onInput" @validation="onValidation($event, 'roof-min-yield')" />
    </FormRow>
    <FormRow
      :label="$t('roofMinModulesTitle')"
      :info-title="$t('roofMinModulesInfoTitle')"
      :info-text="$t('roofMinModulesInfoText')"
    >
      <NumericInput v-model="model.roof_min_modules" optional :edit="isEditing" :units="'Module'" @input="onInput" @validation="onValidation($event, 'roof-min-module')" />
    </FormRow>
    <FormRow
      :label="$t('roofMaxShapeFactorTitle')"
      :info-title="$t('roofMaxShapeFactorInfoTitle')"
      :info-text="$t('roofMaxShapeFactorInfoText')"
    >
      <NumericInput v-model="model.roof_max_shape_factor" optional :edit="isEditing" :units="''" @input="onInput" @validation="onValidation($event, 'roof-max-shape-factor')" />
    </FormRow>
    <FormRow
      :label="$t('fractionTitle')"
      :info-title="$t('roofFractionInfoTitle')"
      :info-text="$t('roofFractionInfoText')"
    >
      <NumericInput v-model="model.roof_fraction" optional :edit="isEditing" :units="'%'" @input="onInput" @validation="onValidation($event, 'roof-fraction')" />
    </FormRow>
    <h3>{{ $t('fassadeTitle') }}</h3>
    <FormRow
      :label="$t('useFassadeTitle')"
      :info-title="$t('useFassadeInfoTitle')"
      :info-text="$t('useFassadeInfoText')"
    >
      <CheckInput
        v-model="model.use_fassade"
        :edit="isEditing"
        :activeLabel="$t('activeLabel')"
        :inactiveLabel="$t('inactiveLabel')"
        @input="onInput"
      />
    </FormRow>
    <FormRow
      :label="$t('fassadeMinYieldTitle')"
      :info-title="$t('fassadeMinYieldInfoTitle')"
      :info-text="$t('fassadeMinYieldInfoText')"
    >
      <NumericInput v-model="model.fassade_min_yield" optional :edit="isEditing" :units="'kWh/kWp'" @input="onInput" @validation="onValidation($event, 'fassade-min-yield')" />
    </FormRow>
    <FormRow
      :label="$t('fassadeMinModulesTitle')"
      :info-title="$t('fassadeMinModulesInfoTitle')"
      :info-text="$t('fassadeMinModulesInfoText')"
    >
      <NumericInput v-model="model.fassade_min_modules" optional :edit="isEditing" :units="'Module'" @input="onInput" @validation="onValidation($event, 'fassade-min-modules')" />
    </FormRow>
    <FormRow
      :label="$t('fractionTitle')"
      :info-title="$t('fassadeFractionInfoTitle')"
      :info-text="$t('fassadeFractionInfoText')"
    >
      <NumericInput v-model="model.fassade_fraction" optional :edit="isEditing" :units="'%'" @input="onInput" @validation="onValidation($event, 'fassade-fraction')" />
    </FormRow>
  </div>
</template>

<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'

import NumericInput from '@/components/shared/forms/NumericInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import CheckInput from '@/components/shared/forms/CheckInput.vue'

export default {
  mixins: [
    //
    ComplexFormMixin,
  ],

  components: {
    NumericInput,
    FormRow,
    CheckInput,
  },

  watch: {
    model() {
      if (this.model === null) {
        this.setDefaults()
      }
    },
  },

  created() {
    if (this.model === null) {
      this.setDefaults()
    }
  },

  methods: {
    setDefaults() {
      this.model = {
        coverage_target: 100,
        roof_min_yield: 800,
        roof_min_modules: 10,
        roof_max_shape_factor: 5,
        roof_fraction: null,
        fassade_fraction: null,
        fassade_min_modules: null,
        fassade_min_yield: null,
      }
      this.onInput()
    },
  },
}
</script>

<style>
.edit-pv-dimensioning .NumericInput {
  max-width: 150px;
}
</style>
