<i18n>
{
  "de": {
    "pendingMessage": "Daten werden gespeichert, bitte warten...",
    "editButtonLabel": "Bearbeiten",
    "cancelButtonLabel": "Abbrechen",
    "saveButtonLabel": "Speichern"
  }
}
</i18n>
<template>
  <div>
    <div v-if="bindFormState.pending">{{ $t('pendingMessage') }}</div>
    <div v-if="!bindFormState.pending">
      <slot></slot>
      <ButtonWrapper v-if="allowEdit">
        <button
          v-if="bindFormState.edit"
          class="button"
          :disabled="!bindFormState.isValid"
          @click="$emit('container', 'save')"
        >
          {{ $t('saveButtonLabel') }}
        </button>
        <button v-if="bindFormState.edit" class="button" @click="$emit('container', 'cancel')">
          {{ $t('cancelButtonLabel') }}
        </button>
        <button v-else class="button" @click="$emit('container', 'edit')">
          {{ $t('editButtonLabel') }}
        </button>
      </ButtonWrapper>
    </div>
    <div v-if="bindFormState.error" class="error-message">
      {{ bindFormState.error?.message || bindFormState.error }}
    </div>
  </div>
</template>
<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

export default {
  components: {
    ButtonWrapper,
  },

  props: {
    bindFormState: {
      type: Object,
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
.error-message {
  color: var(--error-color);
}
</style>
