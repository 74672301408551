<template>
  <img :class="`cui-icon ${className}`" :src="iconSrc" />
</template>

<script>
export default {
  name: 'Icon',

  props: {
    className: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
  },

  computed: {
    iconSrc() {
      return `/icons/${this.icon}.svg`
    },
  },
}
</script>
