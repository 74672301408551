<template>
  <span class="c-info-box">
    <VPopover trigger="hover" placement="top">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-alert-circle"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="12"></line>
        <line x1="12" y1="16" x2="12.01" y2="16"></line>
      </svg>
      <template slot="popover">
        <header v-if="title">{{ title }}</header>
        <main>
          <slot></slot>
        </main>
      </template>
    </VPopover>
  </span>
</template>

<script>
import { VPopover } from 'v-tooltip'

export default {
  components: {
    VPopover,
  },

  props: {
    title: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.feather-alert-circle {
  color: var(--secondary-highlight-color);
  width: 20px;
  height: 100%;
  vertical-align: middle;
}
.feather-alert-circle.edit:hover {
  width: 24px;
  height: 100%;
}
.v-popover {
  display: flex;
}
</style>

<style lang="scss">
.c-info-box {
  flex: none;
}

* + .c-info-box {
  margin-left: var(--spacing-xs);
}
</style>
