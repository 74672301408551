<i18n>
{
  "de": {
    "emptyErrorMessage": "Wert darf nicht leer sein"
  }
}
</i18n>

<template>
  <div class="c-select-field" :class="classes">
    <v-select
      :id="id"
      :class="{ clearable: clearable === true }"
      item-value="itemValue"
      :value="value"
      :clearable="clearable"
      :options="options"
      :reduce="reduce"
      v-on="$listeners"
      @input="updateValue"
      @change="updateValue"
      @open="onListOpen"
    >
      <template v-slot:option="option">
        <VPopover
          trigger="hover"
          offset="8"
          delay="600/50"
          :disabled="!hasTooltip(option.label)"
          popoverInnerClass="value-tooltip-popover"
          popoverArrowClass="value-tooltip-arrow"
        >
          <div class="select-option">
            {{ option.label }}
          </div>
          <template slot="popover">
            <main>
              {{ option.label }}
            </main>
          </template>
        </VPopover>
      </template>
    </v-select>
    <div v-if="validationError" class="validation-error">{{ validationError }}</div>
  </div>
</template>

<script>
import { VPopover } from 'v-tooltip'

export default {
  components: {
    VPopover,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    value: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    reduce: {
      type: Function,
      default: () => {},
    },
  },

  data: () => ({
    tooltips: [],
  }),

  computed: {
    validationError() {
      if (!this.clearable && !this.value) return this.$t('emptyErrorMessage')
      return null
    },

    classes() {
      return {
        invalid: !!this.validationError,
      }
    },
  },

  created() {
    this.$emit('validation', !this.validationError)
  },

  methods: {
    updateValue(value) {
      this.$emit('input', value)
      if (!this.clearable) {
        // Triggering validation only if the field is not clearable
        this.$emit('validation', value)
      }
    },

    onListOpen() {
      this.$nextTick(() => {
        const elements = document.querySelectorAll('.select-option')

        elements.forEach((el, idx) => {
          // Check if the element is overflowing
          if (el.scrollWidth > el.offsetWidth) this.tooltips.push(this.options[idx].label)
        })
      })
    },

    hasTooltip(label) {
      // Check if the label is stored as an overflowing element
      return this.tooltips.includes(label)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-select-field {
  & .v-select {
    &.clearable /deep/ .vs__selected {
      padding: 0 36px 0 0;
    }
    & .select-option {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.invalid .validation-error {
    font-weight: 400;
    font-size: var(--font-xs);
    color: var(--error-color) !important;
  }
}
</style>

<style lang="scss">
// The tooltip is rendered in document body, so we need to style it globally
.value-tooltip-popover {
  background-color: black;
  color: white;
  border: 1px solid black;
  padding: 4px 10px;
  border-radius: var(--box-radius);
  font-size: 14px;

  & .value-tooltip-arrow {
    display: none;
  }
}

.invalid {
  & .vs__dropdown-toggle {
    border: var(--box-error-border) !important;
  }
}
</style>
